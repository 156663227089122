<style lang="scss">
#salary-heads {
  .line-height-50 {
    line-height: 50px;
  }
  .employee-profile {
    width: 20%;
    text-align: left;
    padding: 10px;

    h2 {
      a {
        color: #333;
        font-size: 14px;
      }
    }
    .table-avatar {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      white-space: nowrap;
      font-size: inherit;
      font-weight: 400;
      margin: 0;
      padding: 0;
      vertical-align: middle;
      .avatar {
        background-color: #aaa;
        border-radius: 50%;
        display: inline-block;
        font-weight: 500;
        height: 38px;
        line-height: 38px;
        margin: 0 10px 0 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        width: 38px;
        position: relative;
        white-space: nowrap;
        & > img {
          border-radius: 50%;
          display: block;
          overflow: hidden;
          width: 100%;
        }
      }

      .avatar-xs {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
<template>
  <section id="salary-heads">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-body">
        <v-legend
          :title="'Salary Heads'"
          :description="'Here are all the employees at your company, including terminated ones.'"
        >
        </v-legend>
        <div class="row mb-5">
          <div class="col-lg-4">
            <select
              class="mb-3 mr-sm-3 mb-sm-0 custom-select"
              v-model="searchData.department_id"
              @change="fetchSalaryHeads()"
            >
              <option
                v-for="(dItem, index) in getDepartments"
                :key="index"
                :value="dItem.id"
              >
                {{ dItem.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-4">
            <select
              class="mb-3 mr-sm-3 mb-sm-0 custom-select"
              v-model="searchData.user_id"
              @change="fetchSalaryHeads()"
            >
              <option value="all">--All Employee--</option>
                <option
                        v-for="(empItem, index) in getActiveEmployee"
                        :key="index"
                        :value="empItem.id"
                      >
                        {{ empItem.full_name }}
                      </option>
            </select>
          </div>
        </div>

        <div class="v-data-table v-data-table--fixed-header theme--light">
          <div class="v-data-table__wrapper">
          
            <table class="table table-hover">
              <thead>
                <tr style="background-color: rgb(242, 244, 245)">
                  <th width="20%">Employee</th>
                  <th width="15%">Department</th>
                  <th width="15%">Designation</th>
                  <th width="15%">Earning Head</th>
                  <th width="15%">Deduction Head</th>
                  <th width="5%"></th>
                </tr>
                <tr v-if="loader">
                  <td colspan="12" class="text-center">
                    <data-loader :type="'dark'" :padding="10"></data-loader>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="12"
                    class="text-center"
                    v-if="getSalaryHeads.length < 1 && !loader"
                  >
                    <div class="p-6">
                      <span>No record(s) found</span>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody v-if="getSalaryHeads.length > 0 && !loader">
                <tr v-for="(shItem,index) in getSalaryHeads" :key="index">
                  <td class="">
                    <div class="employee-profile">
                      <h2 class="table-avatar">
                        <a href="/orange/profile" class="avatar avatar-xs"
                          ><img
                            alt=""
                            :src="shItem.employee_avatar"
                        /></a>
                        <a href="/orange/profile" class=""> {{ shItem.employee_full_name }} </a>
                      </h2>
                    </div>
                  </td>
                  <td class="line-height-50">{{shItem.department}}</td>
                  <td class="line-height-50">{{shItem.designation}}</td>
                  <td class="line-height-50">{{shItem.e_amount}}</td>
                  <td class="line-height-50">{{shItem.d_amount}}</td>
                  <td class="line-height-50">
                    <router-link
                      :to="{
                        name: 'payroll.salary.heads.employee',
                        params: { employee_id: shItem.id },
                      }"
                      href="#"
                      class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                      v-b-tooltip
                      title=""
                    >
                      <i class="fa fa-edit text-dark text-sm"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import Legend from "@/includes/content/widgets/Legend";
import dataLoader from "@/includes/content/DataLoader";
import { mapGetters } from "vuex";
import {
  FETCH_EMPLOYEE_SALARY_HEADS,
  FETCH_DEPARTMENTS,
  FETCH_ACTIVE_EMPLOYEE
} from "@/core/services/store/actions.type";

// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tWidth: 80 / 10,
      loader: false,
      searchData: {
        department_id: "",
        user_id: "all",
      },
    };
  },
  computed: {
    ...mapGetters(["getSalaryHeads", "getDepartments","getActiveEmployee"]),
  },
  components: {
    "v-legend": Legend,
    "data-loader": dataLoader,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Salary Heads" }]);
    this.$store.dispatch(FETCH_DEPARTMENTS);
     this.$store.dispatch(FETCH_ACTIVE_EMPLOYEE);
  },
  methods: {
    fetchSalaryHeads() {
      this.loader = true;
      this.$store
        .dispatch(FETCH_EMPLOYEE_SALARY_HEADS, this.searchData)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  filters: {
    doubleDigit(number) {
      return number > 9 ? number : "0" + number;
    },
  },
};
</script>
